<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <h3 class="text-h5 mb-2">Brides</h3>
    </div>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th style="max-width: 50px" class="text-left">
              №
            </th>
            <th>fio</th>
            <th>birthDate</th>
            <th>height</th>
            <th>weight</th>
            <th>isApproved</th>
            <th>approvalTime</th>
            <th>activeUntil</th>
            <th v-if="ableToAction" class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="faq-tr"
              v-for="(item, itemIndex) in list"
              :key="itemIndex"
          >
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.fio }}</td>
            <td>{{ item.birthDate | dateCommonFormat }}</td>
            <td>{{ item.height }}</td>
            <td>{{ item.weight }}</td>
            <td>{{ item.isApproved }}</td>
            <td>{{ item.approvalTime | dateCommonFormatWithDTime }}</td>
            <td>{{ item.activeUntil }}</td>
            <td v-if="ableToAction">
              <v-row justify="center">
                <v-icon
                    title="APPROVE"
                    v-if="can($permissions['BRIDE.APPROVE']) && !item.isApproved"
                    @click="toggleApprove(item.id)"
                    class="cursor-pointer mr-2">mdi-check-decagram
                </v-icon>
                <v-icon
                    title="DELETE"
                    v-if="can($permissions['BRIDE.DELETE'])"
                    @click="deleteItem(item.id)"
                    class="cursor-pointer mr-2">mdi-delete
                </v-icon>
                <v-icon
                    title="DECLINE"
                    v-if="can($permissions['BRIDE.DECLINE'])"
                    @click="declineItemModal(item.id)"
                    class="cursor-pointer">
                  mdi-cancel
                </v-icon>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <div v-if="totalPages > 1" class="text-center mt-3">
      <v-pagination
          v-model="pagination.page"
          :length="totalPages"
      ></v-pagination>
    </div>
    <!--  Modals  -->
    <v-dialog
        v-model="dialogDecline"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Decline Reason</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Reason"
                    outlined
                    hide-details="auto"
                    v-model="declineForm.declineReason"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialogDecline = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :disabled="!declineForm.declineReason"
              @click="declineItem">
            Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      filterPeople: {
        "pageNumber": 1,
        "pageSize": 20,
        "countryId": null,
        "districtId": null,
        "cityId": null,
        "ageFrom": null,
        "ageTo": null,
        "bodyTypes": [],
        "skinColors": [],
        "dressingStyles": [],
        "eduDegrees": [],
        "relationState": [],
        "hasChildren": null,
        "sortColumn": "default"
      },
      list: [],
      item: {
        id: 0,
        userFio: null,
        email: null,
        phone: null,
        rate: 0,
        content: null
      },
      declineForm: {
        id: 0,
        declineReason: ''
      },
      pagination: {
        page: 1,
        pageSize: 10,
        totalCount: 0
      },
      dialog: false,
      dialogDecline: false,
      update: false
    }
  },
  computed: {
    ableToAction() {
      return this.can(this.$permissions['BRIDE.DELETE']) || this.can(this.$permissions['BRIDE.DECLINE']) || this.can(this.$permissions['BRIDE.APPROVE'])
    },
    totalPages() {
      return Math.ceil(this.pagination.totalCount / this.pagination.pageSize);
    }
  },
  methods: {
    async getList() {
      this.showLoader();
      try {
        this.filterPeople.pageNumber = this.pagination.page;
        this.filterPeople.pageSize = this.pagination.pageSize;
        const res = await this.$http.post('/api-people/getAdminBrides', this.filterPeople);
        this.list = res.result.data;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    declineItemModal(id) {
      this.declineForm.declineReason = null;
      this.declineForm.id = id;
      this.dialogDecline = true;
    },
    async declineItem() {
      try {
        const res = await this.$http.post(`/api-people/declineBride`, this.declineForm);
        this.list = res.result.data;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.dialogDecline = false;
      }
    },
    async toggleApprove(id) {
      try {
        const res = await this.$http.get(`/api-people/approveBride?brideId=${id}`);
        this.list = res.result.data;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async deleteItem(id) {
      try {
        const res = await this.$http.get(`/api-people/deleteBride?brideId=${id}`);
        this.list = res.result.data;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
  },
  watch: {},
  created() {
    this.getList();
  }
}
</script>

<style scoped>
.faq-tr:first-child .move-icon-up {
  display: none;
}

.faq-tr:last-child .move-icon-down {
  display: none;
}
</style>